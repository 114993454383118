/**
 * Registration component to sign up an account lead or complete a registration invite.
 */
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from "@angular/forms";
import { ModalController, NavController, ToastController } from "@ionic/angular";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs-compat/operator/map";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { TermsOfUseModal } from "../../pages/modal/terms-of-use-modal/terms-of-use-modal.component";
import { ValidationService } from 'src/app/services/validation.service'
import { IUser } from "src/app/interfaces/user.interface";
import { objectifyQueryParams } from "src/app/util/util";

@Component({
  selector: "registration-form",
  templateUrl: "./registration-form.component.html",
  styleUrls: ["./registration-form.component.scss"],
})
export class RegistrationForm implements OnInit {
  // second attempt to provide a better ui - reg callback will be handled in the parent
  // @Input() onRegOverride: () => void
  @Input() onChange: (isValid: boolean, form: { firstname: string, lastname: string, email: string, password: string }) => void
  @Input() user: IUser
  @Input() isInvite: boolean
  @Input() disabled: boolean
  @Input() removePasswordInput: boolean // if you're partially registered, you can't send password again
  @Input() inputOverride // allow us to start default and pass values in

  form = this.fb.group({
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    email: ['', {
      validators: [
        Validators.required, 
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), // Validator.email allows a@b
      ],
      asyncValidators: [this.validationService.emailAvailability()],
      updateOn: 'blur'
    }],
    phone: ['', [
      Validators.required, 
      Validators.pattern(/\d{10}/),
      Validators.maxLength(10),
    ]],
    brokerage: [''],
    password: ['', [Validators.required]],
    userType: ['', Validators.required],
    // NOTE: this currently doesn't get cleared if the user changes their mind
    userTypeOtherDesc: [null]
  })

  showPassword = false

  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    public fb: FormBuilder,
    public userService: UserAdminService,
    public validationService: ValidationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    console.log('--- ON CHANGES', changes)
    if (changes.disabled && changes.disabled.currentValue) {
      this.form.disable()
    }
    // note - instead of hiding the value, the input could be set password if requested...
    if (changes.removePasswordInput && changes.removePasswordInput.currentValue) {
      $('label[for="password"]').remove()
    }
    if (changes.inputOverride && changes.inputOverride.currentValue) {
      // alert('OVERRIDING VALUES')
      for (let key in changes.inputOverride.currentValue) {
        console.log(`key = ${key} and currentValue = `, changes.inputOverride.currentValue[key])
        this.form.patchValue({ [key]: changes.inputOverride.currentValue[key] })
      }
    }
  }

  async ngOnInit() {
    if (this.user) {
      this.form.get('email').clearAsyncValidators()
      $('#email').attr('disabled', 'true')
      if (!this.user.account_lead) {
        $('#brokerage').attr('disabled', 'true')
      }
      this.form.patchValue({ 
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        email: this.user.local.email,
        phone: this.user.profile.phone,
        brokerage: this.user.profile.brokerage
      })
    }

    // alert(`disabled ? ${this.disabled} | removepassword? = ${this.removePasswordInput}`)
    if (this.disabled) {
      this.form.disable()
    }

    this.form.valueChanges.subscribe(values => {
      this.onChange(this.form.valid, values)
    })

    // generate inputs for development - this blocks it from ever happening on deployed versions
    // note, team members don't need dev testing because they need to load their partially registered user
    if (!window.location.href.includes('valuclick.co') && !objectifyQueryParams().id) {
      // this.setupDevTesting()
    }
  }

  /**
   * Quick setup for development.
   */
	setupDevTesting() {
		// const date = moment().format('YYYY-MM-DD_HH-mm-ss')
    const date = moment().unix()
    const tag = `new-reg-${date}`
    this.form.patchValue({
      firstname: tag,
      lastname: 'jacobs',
      email: `${tag}@jacobscreative.io`,
      password: 'password123!',
      phone: '1234567890',
      // brokerage: `${date} Brokerage Inc.`
      userType: 'realtor'
    })
	}

  // ------------------- 
  // These are for when the component is non controlled... but seems that's the only way this will be moving forward.
  // probably can delete these below
  /**
   * @todo
   * Handle a user coming in from an invitation email, sent by their account lead.
   */
  async loadInvite() {}

  /**
	 * Open the Terms of Use Modal.
	 */
	async toggleTermsModal() {
		const modalCtrl = await this.modalController.create({ component: TermsOfUseModal })
		await modalCtrl.present()
	}
}