import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavController, AlertController, ToastController, MenuController, LoadingController, ModalController } from '@ionic/angular';
import { HomePage } from '../home/home';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../services/appservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { AppInfoService } from 'src/app/services/appinfo.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ERROR_MESSAGES } from 'src/app/util/errorUtil';
import { CheckoutRequiredModal } from '../modal/checkout-required-modal/checkout-required.component';
import { TITLES } from 'src/app/util/constants';

enum loginstate {
  login,
  resetpass,
  activate
}

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['./login.scss'],
})
export class LoginPage implements AfterViewInit, OnInit {

  // showkey Enum used to select the right caption.
  public paswordcaption: Array<string> = ['Password', 'New Password', 'Password'];

  public mail: string;
  public password: string;
  public token: string;
  public showkey: loginstate = loginstate.login;
  public appservices = [];
  public serviceURL = '/';
  public pendingAuthCheck = true;

  public onLoginForm: FormGroup;

  private videoModalCtrl: any;
  private welcomeVideo: SafeResourceUrl;

  public videoDone = false;

  public modalCtrl: any;

  constructor(public auth: AuthService,
    public nav: NavController,
    public forgotCtrl: AlertController,
    public menu: MenuController,
    public toastCtrl: ToastController,
    private readonly appService: AppService,
    private formBuilder: FormBuilder,
    public loadingCtrl: LoadingController,
    public router: Router,
    public appInfoService: AppInfoService,
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
  ) {
    document.title = TITLES.LOGIN

    // this.menu.swipeEnable(false);
    this.serviceURL = this.appService.getDetaulSvcUrl();

    let serviceSkip = false;
    if (!this.serviceURL || this.serviceURL.length === 0) {
      serviceSkip = true;
    }

    this.saveService(this.serviceURL);
  }

  ngOnInit(): void {
    this.menu.enable(false, 'main');
  }

  ngAfterViewInit(): void {
      this.auth.isAuthenticated().subscribe((authed) => {
        if (authed) {
          this.menu.enable(true, 'main');
          this.nav.navigateRoot('home');
        } else {
          this.loadLogin();
        }
      }, err => {
        this.loadLogin();
      });
  }

private async loadLogin() {
  this.pendingAuthCheck = false;
  window.sessionStorage.clear();
  window.localStorage.clear();

  const { welcomeUrl } = await this.appInfoService.welcomeURL().toPromise() as { welcomeUrl: string }

  if (welcomeUrl) {
    // turning this off for the time being
    this.videoDone = true
    this.welcomeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(welcomeUrl + '?autoplay=1');
    // if (document.cookie.indexOf('video') != -1) {
    //     // this has been played - ignore
    //     this.videoDone = true;
    //   } else {
    //   console.log("NEED TO WATCH VIDEO!!")
    //   this.appInfoService.welcomeURL().subscribe((welcomeRes: any) => {
    //     console.log("--- welcomeRes:", welcomeRes)
    //     if (welcomeRes.welcomeUrl) {
    //       this.welcomeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(welcomeRes.welcomeUrl + '?autoplay=1');
    //       this.videoDone = false;
    //     } else {
    //       this.videoDone = true;
    //     }
    //   });
    // }
  }

  this.onLoginForm = this.formBuilder.group({
    'email': [null, Validators.compose([
      Validators.required
    ])],
    'password': [null, Validators.compose([
      Validators.required
    ])],
    'token': [null, Validators.compose([])]
  });

}

  // go to register page
  register() {
    return this.nav.navigateForward(['register']);
  }

  // login and go to home page
  login() {

    if (this.showkey === loginstate.resetpass) {

      return this.auth.changePassword(this.onLoginForm.value.email, this.onLoginForm.value.password, this.onLoginForm.value.token).subscribe(
        (loggedin: Boolean) => {
          this.menu.enable(true, 'main');
          this.nav.navigateRoot('home');
        });
    // } else if (this.showkey === loginstate.activate) {
    //   return this.auth.signIn(this.onLoginForm.value.email, this.onLoginForm.value.password, this.onLoginForm.value.token).subscribe(
    //     (loggedin: Boolean) => {
    //       this.menu.enable(true, 'main');
    //       this.nav.navigateRoot('home');
    //     });
    } else {
      const token = this.showkey === loginstate.activate ? this.onLoginForm.value.token : undefined
      return this.auth.signIn(this.onLoginForm.value.email, this.onLoginForm.value.password, token).subscribe(
        (loggedin: Boolean) => {
          this.menu.enable(true, 'main');
          this.nav.navigateRoot('home');
        },
        async (error) => {
          console.log("ERROR: ", error, error.error, error.error.message)
          if (error.error.message === ERROR_MESSAGES.MUST_INPUT_CC) {
            this.modalCtrl = await this.modalController.create({
              component: CheckoutRequiredModal,
              componentProps: { email: this.onLoginForm.value.email }
            })
            this.modalCtrl.present()
          }
        });
    }
  }

  loadServices(username) {
    if (!username.ngControl.invalid) {
      if (this.appService.needsSvcURL()) {
        return this.appService.listSvcURL(username.value).subscribe((svcs: any[]) => {
          console.log('got services list: ', svcs);
          // if we get more than one service then setup the end-user selection
          // if (svcs.length > 1) {
          this.appservices = svcs;
          if (this.appservices && this.appservices.length) {
            this.serviceURL = this.appservices[0].baseurl;
          }

          console.log('returning: ', this.serviceURL);
          // save the first one
          return svcs;
          // } else {
          // Otherwise just set the service.
          //  this.appService.saveSvcURL(svcs[0].baseurl);
          // }
        }, (err: any) => {
          this.serviceURL = this.appService.getDetaulSvcUrl();
        });
      } else {
        this.serviceURL = this.appService.getDetaulSvcUrl();
      }
    }
  }
  saveService(service) {
    if (service && service.length) {
      this.appService.saveSvcURL(service);
    }
  }

  cancellogin() {
    window.location.reload();
  }

  activateaccount() {
    return this.showkey = loginstate.activate;
  }

  resetpassword() {
    return this.showkey = loginstate.resetpass;
  }

  requestresettoken() {
    return this.auth.resetPassword(this.mail, 'thisisadummy').subscribe(
      (loggedin: Boolean) => {
        // nothing much to do here.
      });
  }

  forgotPass() {
    if (this.serviceURL && this.serviceURL.length) {
      this.saveService(this.serviceURL);
    } else {
      const toast = this.toastCtrl.create({
        message: 'You must select a service from the \'Service\' options.',
        duration: 6000,
        position: 'top'
      }).then(ctl => {
        ctl.present();
      });
      return toast;
    }
    const forgot = this.forgotCtrl.create({
      header: 'Forgot Password?',
      message: 'Enter you email address to send a reset link password.',
      inputs: [
        {
          name: 'email',
          placeholder: 'Email',
          type: 'email'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Send',
          handler: data => {
            return this.auth.resetPassword(data.email, 'thisisadummy').subscribe(
              (loggedin: Boolean) => {
                // nothing much to do here.
              });
          }
        }
      ]
    }).then(ctl => {
      ctl.present();
    });
  }

  openVideo() {
    // window.open(this.welcomeVideo, '_video');
  }


  closeVideo() {
    document.cookie = 'video=true;expires=Thu, 18 Dec 2099 12:00:00 UTC;';
    // window.localStorage.setItem('video', 'true');
    window.location.reload();
  }

}
