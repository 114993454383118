import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoadingController, ModalController, NavController, ToastController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";
import { TITLES } from "src/app/util/constants";
import { objectifyQueryParams } from "src/app/util/util";

@Component({
	selector: 'page-register-activate',
	templateUrl: 'register-activate.html',
	styleUrls: ['./regsiter-activate.scss']
})
export class RegisterActivate implements OnInit {
	private header = "Activating Account..."
	email: string
	password: string
	needPassword = false
	showPassword = false
	code: string
	loading = false
	invalidPassword = false
	bodytxt = ''

	constructor(
		public nav: NavController,
		private activatedRoute: ActivatedRoute,
		public auth: AuthService,
		public toastCtrl: ToastController,
		public modalController: ModalController,
		public loadingController: LoadingController) { }

	ngOnInit(): void {
		document.title = TITLES.ACTIVATION
		this.activate()
	}

	async activate() {
		// const loader = await this.loadingController.create({})
		// loader.present()
		
		const keytoken = this.activatedRoute.snapshot.paramMap.get('keytoken')
		const { activationLinkId } = objectifyQueryParams()

		try {
			const activated = await this.auth.activateAccount(keytoken, activationLinkId)
			if (activated) {
				this.header = 'Account activated!'
				setTimeout(() => {
					this.nav.navigateForward('/home')
				}, 500)
			}
		} catch (e) {
			console.error('Error activating user')
			console.log(e)
			this.header = 'Activation Failed'
			if (e.error && e.error.message && e.error.message.includes('No user with this code')) {
				this.bodytxt = 'No user with this code. Make sure you used the most recent link, each resend link changes the activation code.'
			}
		}

		// loader.dismiss()
	}

	async submit() {
		this.loading = true
		try {
			this.nav.navigateRoot('home')
		} catch (e) {
			console.error(`Error is: `, e)
			// toast lets you know about that
			// alert('Error activating account.')
			this.invalidPassword = true
		}
		this.loading = false
	}
}
