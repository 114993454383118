/**
 * Container for the registration process.
 * 
 * Registration consists of 3 parts:
 * 1. DB & Login info
 * 2. Subscription & Payment info
 * 3. Verifying your email
 * 
 * This container will handle unauthenticated registering users and partially registrered users.
 */
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ModalController, NavController, ToastController } from "@ionic/angular";
import { IUser, VcPaymentMethod } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { delay, objectifyQueryParams } from "src/app/util/util";
import { TermsOfUseModal } from "../modal/terms-of-use-modal/terms-of-use-modal.component";
import { TITLES } from "src/app/util/constants";

// const STEPS = {
// 	GEN_REG: 1,
// 	ACTIVATE: 2,
// 	// CC: 3 // this state is is 1 is successful, user refreshes, they can't add password so we're stuck here
// }

declare global {
  interface Window {
		rewardful: any; // You can specify the correct type if you know it
		Rewardful: any;
		// Trackdesk
		trackdesk: any;
  }
}


/**
 * This form assumes the 
 */
@Component({
	selector: 'registration-ctr',
	templateUrl: './registration-ctr.html',
	styleUrls: ['./registration-ctr.scss']
})
export class RegistrationCtr implements OnInit {
	step = 1
	user: IUser
	account_lead = true
	brokerage_code = null
	loadingInvite = true
	needsToCompletePaymentMethod = false
	regInputOverride = {}
	removePasswordInput = false

	constructor(
		private auth: AuthService,
		private nav: NavController,
		private toastCtl: ToastController,
		private userService: UserAdminService,
		// private activatedRoute: ActivatedRoute,
		private modalCtl: ModalController,
		// private renderer: Renderer2 // for rewardful interaction
	) {}

	async ngOnInit() {
		document.title = TITLES.REGISTRATION

		// window.rewardful('ready', () => {
		// 	// this should print the UUID
		// 	console.log('--- REWARDFUL LOADED: ', window.Rewardful, window.Rewardful.referral)
    // })
		console.log('-- trackdesk object: ', window.trackdesk)

		const { id, code, tier, coupon } = objectifyQueryParams()
		if (tier) {
			// this.tier = 'eat me'
			// console.log('--- the tier is: ', tier)
			this.tier = tier
		}
		if (coupon) {
			this.coupon = coupon
		}
		if (id && code) {
			this.account_lead = false
			await this.loadInvite(id, code)
			this.loadingInvite = false
			return
		}
		this.loadingInvite = false

		if (!this.auth.getUser()) {
			return
		}

		this.user = await this.auth.getFreshUser(true)

		this.email = this.user.local.email
		this.firstname = this.user.firstname
		this.lastname = this.user.lastname	
		this.phone = this.user.profile.phone
		this.brokerage = this.user.profile.brokerage
		this.userType = this.user.profile.userType
		this.userTypeOtherDesc = this.user.profile.userTypeOtherDesc

		// if email exists, user is attempting to verify account
		if (this.email) {
			this.step = 2
		}

		// check payment info
		// const { paymentmethods } = await this.userService.getPaymentMethods(this.user._id)
		// if (paymentmethods.length) {
		// 	this.step = 2
		// } else {
		// 	console.log('User is signed up & logged in, but they need to input payment info.')
		// 	this.needsToCompletePaymentMethod = true
		// 	$('#reg-title').text('Complete Sign Up')
		// 	this.validReg = true
		// 	this.failedPayment = true
		// 	this.regInputOverride = { 
		// 		email: this.email,
		// 		firstname: this.firstname,
		// 		lastname: this.lastname,
		// 		phone: this.phone,
		// 		brokerage: this.brokerage
		// 	}
		// 	this.removePasswordInput = true
		// 	console.log('.... inputOverride = ', this.regInputOverride)
		// 	// console.log('JUGGANAUGHT == ', this.validReg, this.failedPayment, this.tier, this.validPayment)
		// 	// $('#registerBtn span').text('Finish ')
		// }
	}

	async routeHome() {
		if (this.user) {
			if (confirm('Sure you want to exit? You will be removed from the system and forced to sign up again')) {
				try {
					await this.auth.deleteAccount()
				} catch (e) {
					console.error('error: ', e)
					alert('Error deleting account. Please contact the VALUclick staff if you need assistance finish signing up.')
				}
				return this.auth.signOut()
			}
		}
		this.nav.navigateRoot('/')
	}

	async loadInvite(id: string, code: string) {
		try {
			const user = await this.userService.getUserInvite(id, code).toPromise()
			this.user = user
			this.firstname = user.firstname
			this.lastname = user.lastname
			this.email = user.local.email
			this.phone = user.profile.phone
			this.brokerage = user.profile.brokerage
			this.brokerage_code = user.brokerage_code
		} catch (e) {
			console.error(`Error loading invite: `, e)
			const toast = await this.toastCtl.create({position: 'top',duration: 3000, message: 'Error loading invite. Check invite link and try again.'})
			await toast.present()
		}
	}

	//---------------------------------------- Register
	loadingReg = false
	validReg = false
	firstname: string
	lastname: string
	email: string
	phone: string
	brokerage: string
	password: string	
	userType: string
	userTypeOtherDesc: string

	onChange = (isValid, form) => {
		// skip the updating
		if (this.needsToCompletePaymentMethod) {
			return
		}

		this.validReg = isValid
		// $('#registerBtn span').text(isValid ? 'Continue' : 'Complete Form to Continue')
		// console.log(form, isValid, '... and stuff')
		this.firstname = form.firstname
		this.lastname = form.lastname
		this.email = form.email
		this.phone = `${form.phone}` // gotta be a string
		this.brokerage = form.brokerage
		this.password = form.password
		this.userType = form.userType
		this.userTypeOtherDesc = form.userTypeOtherDesc
	}
	
	/**
	 * Add user to the DB, creating their stripe customer ID.
	 * No email is sent if payment is required.
	 * Thus, if user is account lead they'll need to explicitely ask for the activation code
	 */
	register = async () => {
		this.loadingReg = true
		try {
      const res = await this.auth
        .signUp(
          this.email,
          this.password,
          this.firstname,
          this.lastname,
          this.phone,
          this.brokerage,
          this.tier,
          this.brokerage_code,
          this.account_lead,
          null,
					this.userType,
					this.userTypeOtherDesc
        )
        .toPromise();

			/** 
			 * @deprecated 
			 * as of 1.25.25 we're not requiring CC, offering a single account type (no teams) 
			 * or doing affiliate marketing.
			 */
      // if this is an account lead, they still need to input billing and verify the email
      // invitees implicitely verify their email by clicking this link in the first place.
      // if (this.account_lead) {
			// 	// lock the form on successful insertion to the DB
			// 	this.needsToCompletePaymentMethod = true

			// 	// once user is created, add them to trackdesk so their conversion can be registered
			// 	// upon payment
			// 	if (location.origin.includes('https://app.valuclick')) {
			// 		window.trackdesk('valuclick', 'externalCid', {
			// 			externalCid: this.email,
			// 			revenueOriginId: 'c00e1ee0-9d11-428e-854a-205860ad33c0'
			// 		})
			// 	}

      //   this.user = res.user;
      //   await this.savePayment()
      //   if (!this.failedPayment) {
      //     await this.sendActivationEmail();
			// 		// window.rewardful('convert', { email: this.email })
      //     this.step = 2;
      //   } else {
      //     $("#registerBtn span").text("Fix payment method to continue");
      //     // for now we'll lock the form
      //     this.needsToCompletePaymentMethod = true;
      //     // this.regInputOverride = {

      //     // }
      //     this.regInputOverride = {
      //       email: this.email,
      //       firstname: this.firstname,
      //       lastname: this.lastname,
      //       phone: this.phone,
      //       brokerage: this.brokerage,
      //     };
      //   }
      // } else {
      //   await this.auth.getFreshUser(true);
      //   this.nav.navigateRoot("home");
      // }

			this.user = res.user;
			this.step = 2;
    } catch (e) {
			console.error(`Error registering user: `, e)
			const toast = await this.toastCtl.create({message: 'Error has occured.', duration: 3000, position: 'top'})
			await toast.present()
		}
		this.loadingReg = false
	}

	/**
	 * Step 1: we sign up the user. once they have a stripe customer ID i can get a setup intent.
	 * Step 2: we save the payment details. If that fails, we use this instead of the regsiter function.
	 */
	failedPaymentRetry = async () => {
		this.loadingReg = true
		await this.savePayment()
		this.loadingReg = false

		if (!this.failedPayment) {
			await this.sendActivationEmail()
			this.step = 2
		}
	}

	//---------------------------------------- Plan & Payment
	tier: string
	setTier = t => this.tier = t

	stripe: stripe.Stripe
	card: stripe.elements.Element
	paymentMethod: VcPaymentMethod
	validPayment = false
	loadingPayment = false
	failedPayment = false

	// a bit misleading, it's more like a "free-trial code"
	// just kidding. it's a coupon again.
	// so coupone use to mean free trial code
	// not it means stripe coupon... for good
	coupon: string = ''
	setCoupon = evt => {
		console.log('-- setting coupon: ', evt)
		const c = evt.target.value
		this.coupon = c
	}

	onCardChange = async (event: stripe.elements.ElementChangeResponse, stripeInstance: stripe.Stripe, card: stripe.elements.Element) => {
		// console.log('on card change', event)
		// console.log('JUGGANAUGHT TWO == ', this.validReg, this.failedPayment, this.tier, this.validPayment)

		this.stripe = stripeInstance
		this.card = card
		let displayError = document.getElementById('card-element-errors');
		if (event.error && displayError) {
			displayError.textContent = event.error.message;
			displayError.style.color = '#eb1c26';
		} else {
			// displayError.textContent = '';
		}
		if (event.complete) {
			this.validPayment = true
			if (this.validPayment && this.tier) {
				// $('#paymentBtn span').text('Continue')
			}
		} else {
			this.validPayment = false
			$('#paymentBtn span').text('Choose Plan to Continue')
		}
	}

	async savePayment() {
		const intent = await this.userService.getStripeIntent(this.user._id)
		const cardParams = { 
			payment_method: { 
				card: this.card,
				billing_details: {
					name: `${this.firstname} ${this.lastname}`,
					email: this.email,
					phone: this.phone
				}
			}
		}
		const res = await this.stripe.confirmCardSetup(intent.client_secret, cardParams)
		if (!res.setupIntent) {
			console.log('res = ', res)
			this.failedPayment = true
			return alert('Error setting up card. Please review credit card input and try again.')
		}

		try {
			// note - don't use coupon for trial users
			await this.userService.setSub(this.user._id, this.tier, this.tier === 'TRIAL' ? null : this.coupon)
			this.failedPayment = false
		} catch (e) {
			console.log('-00 SAVEPAMYENT ERROR = ', e)
			this.failedPayment = true
			if (e.error && e.error.statusCode == 400 && e.error.message.includes('Incorrect free trial code')) {
				const toast = await this.toastCtl.create({message: e.error.message, duration: 5000, position: 'top'})
				await toast.present()
			} else if (e.error && e.error.statusCode === 400 && e.error.message == 'Invalid coupon code') {
				$('#vc-coupon label').addClass('error')
			} else {
				console.log('unknown error has occured: ', e)
				throw e
			}
		}
	}

	submitPayment = async () => {
		this.loadingPayment = true
		try {
			await this.savePayment()
		} catch (e) {
			console.error(`Error saving payment info.`)
			this.loadingPayment = false
			const toast = await this.toastCtl.create({message: 'Error saving payment info. Review the card details and try again.', duration: 3000, position: 'top'})
			return await toast.present()
		}
		
		try {
			const res = await this.userService.setSub(this.user._id, this.tier)
			this.user = res.user
			this.step = 3
		} catch (e) { 
			console.error(`Error setting subscription.`)
			this.loadingPayment = false
			const toast = await this.toastCtl.create({message: 'Error setting subscription', duration: 3000, position: 'top'})
			return await toast.present()
		}

		try {
			await this.sendActivationEmail()
		} catch (e) {
			console.error(`Error sending activation email: `, e)
			const toast = await this.toastCtl.create({message: 'Error sending activation email. Check email and try resending.', duration: 3000, position: 'top'})
			await toast.present()
		}

		this.loadingPayment = false
	}

	//---------------------------------------- Activation Code
	code: string
	loadingCode = false

	/**
	 * The ideal activation email that's sent - it can do an auto login since we have access to the password.
	 * Otherwise, user needs to provide at the time of activation (if they're not already logged in)
	 */
	sendActivationEmail = async () => {
		try {
			// if (this.email && this.password) {
			// 	await this.auth.sendActivationEmail(this.email, this.password).toPromise()
			// } else {
			// 	await this.auth.getActivationEmail()
			// }
			await this.auth.sendActivationEmail()
			const toast = await this.toastCtl.create({ message: 'Activation Email Sent', duration: 3000, position: 'top' })
			await toast.present()
		} catch (e) {
			console.error(`Error activing email: `, e)
			const toast = await this.toastCtl.create({ message: 'Error sending activation email', duration: 3000, position: 'top' })
			await toast.present()
		}
	}

	onActivationCodeChange = (code) => {
		this.code = code
	}

	submitActivationCode = async () => {
		this.loadingCode = true
		try {
			const res = await this.auth.activateByCode(this.code)
			const toast = await this.toastCtl.create({ message: 'Congratulations! Welcome to VALUclick.', duration: 3000, position: 'top' })
			await toast.present()
			this.nav.navigateRoot('home')
		} catch (e) {
			console.error(`error with activation code: `, e)
			const toast = await this.toastCtl.create({ message: 'Error with activation token. Verify and try again.', duration: 3000, position: 'top' })
			await toast.present()
		}
		this.loadingCode = false
	}

	onEmailUpdate = async (user) => {
		// we probably could set the returned user but we'll just refresh
		this.user = await this.auth.getFreshUser(true)
		this.email = this.user.local.email	
	}

  /**
	 * Open the Terms of Use Modal.
	 */
	async toggleTermsModal() {
		const modal = await this.modalCtl.create({ component: TermsOfUseModal })
		await modal.present()
	}
}