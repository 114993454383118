/**
 * Service that wraps the web-installed PostHog analytics client.
 * @note Using the global PostHog client since we cannot install the npm package due to Node version constraints.
 */
import { Injectable } from '@angular/core';
import { IAddress, IProperty } from '../interfaces/property.interface';

declare var posthog: any;

@Injectable({
  providedIn: 'root'
})
export class PosthogService {
  private isPosthogAvailable: boolean;

  constructor() {
    this.isPosthogAvailable = typeof posthog !== 'undefined';
		if (!this.isPosthogAvailable) {
			console.error('PostHog is not unavailable');
		}
  }

	setSession(id: string, email: string, name: string, roles: string[]) {
		if (!this.isPosthogAvailable) {
			return
		}
		posthog.identify(id, {email, name, roles})
	}

  trackEvent(eventName: string, properties?: any) {
		if (!this.isPosthogAvailable) {
			return
		}
		posthog.capture(eventName, properties);
  }

	capturePageView() {
		posthog.capture('$pageview')
	}

	capturePropertySearch(duration: number, addressObj: IAddress, success: boolean, errMsg: null|string, property: IProperty) {
		const event = {
			duration,
			success,
			errMsg,
			fullAddress: addressObj.formatted_address,
			city: addressObj.city,
			state: addressObj.state,
			zip: addressObj.zip,
			VALU: null,
			DOM: null,	
			// TODO - identify if this was cached. but for now, ignore this 
		}

		// low-res assessment since it's frontend only
		// but this will allow us to see what searches are coming back with results
		// this allows us to see full and partial CMA success rates
		if (success) {
			try {
				event.VALU = property.eccovals[0].priceadjustment
			} catch (e) {}
			try {
				event.DOM = property.eccovals[0].valuDOM
			} catch (e) {}
		}
	
		this.trackEvent(`property:address_search`, event)
	}

	handleLogout() {
		posthog.capture('app:logout')
		posthog.reset()
	}
}
